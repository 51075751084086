.nobo_form{
  width: 100%;
}
@media screen and (max-width: 425px) and (min-width: 376px) {
  .nobo_form{
    height: 1950px;
  }
}
@media screen and (max-width: 375px) and (min-width: 326px) {
  .nobo_form{
    height: 100%;
  }
}
@media screen and (max-width: 325px) {
  .nobo_form{
    height: 100%;
  }
}
@media (max-width: 990px){
.container-top{
  /* style={{overflowY:"scroll",height:"80vh"}} */
  overflow-y: scroll;
  height:80vh;
}
}
.tab-main-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.tabs-flex {
  display: flex;
}

.tab-styling {
  font-size: 13px !important;
  /* margin-right: 20px !important; */
}

.add-btn {
  background-color: #3aab7b;
  border: 1px solid #3aab7b;
  padding: 10px 20px !important;
  border-radius: 3px;
  color: #ffffff;
  font-size: 16px !important;
}

.add-btn:hover {
  background-color: #3aab7b !important;
  border: 1px solid #3aab7b !important;
}

.delete-btn {
  background-color: #c82333;
  border: 1px solid #c82333;
  padding: 10px 20px !important;
  border-radius: 3px;
  color: #ffffff;
  font-size: 16px !important;
}

.delete-btn:hover {
  background-color: #c82333 !important;
  border: 1px solid #c82333 !important;
}

.radio-div {
  display: flex;
  flex-direction: column;
}

.radio-btn-div {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.radio-btn {
  font-size: 14px;
}

.main-container {
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 50px 80px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.add-btn {
  background-color: #3aab7b;
  border: 1px solid #3aab7b;
  padding: 6px 12px;
  border-radius: 3px;
  color: #ffffff;
}

.delete-btn {
  background-color: #c82333;
  border: 1px solid #c82333;
  padding: 6px 12px;
  border-radius: 3px;
  color: #ffffff;
}

.radio-div {
  display: flex;
  flex-direction: column;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.radio-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.heading {
  font-size: 32px;
  font-weight: 350;
}

.padding-class {
  margin-bottom: 12px;
}

.padding-class-new {
  margin-bottom: 20px;
}

input::placeholder {
  color: #c82333;
}

.requiredelement:after {
  content: "  *";
  color: red;
  font-size: 14px;
  padding-left: 2px;
}

.placeholder-Class::placeholder {
  color: #000 !important;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 22px;
  }

  .client-heading {
    font-size: 20px;
  }

  .res-size {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .tab-flex {
    flex-direction: column;
  }

  .tab-styling {
  font-size: 11px !important;
}


  .button-wrapper {
    margin-top: 30px;
    flex-direction: column;
  }
  .radio-wrapper {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .top-btn-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .heading {
    font-size: 18px;
  }

  .client-heading {
    font-size: 16px;
  }

  .res-size {
    font-size: 12px !important;
  }

  .main-container {
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 40px;
  }
}
