.main-footer {
    
 display: none;
  
}
@media (min-width: 990px){
  .main-footer {
    
    background: #f6f6f6;
    padding-top: auto;
    position: relative;
    bottom: 0;
    display:flex;
    width: 100%;
    
  }
}
