/* navbar */

@media only screen and (max-width: 425px) { 
  .navbar_wrapper {
    padding: 10px 0px;
    width: 100%;
    background: #f6f6f6;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .logo_wrapper{
    text-align: center;
    padding-left: 20px;
  }
  .login-btn{
    position: relative;
    text-align: center;
    padding: 10px 0px;
    padding-left: 20px;
  }
 } 
@media only screen and (min-width: 426px) { 
  .navbar_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 100%;
    background: #f6f6f6;
    position: relative;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .login-btn{
    position: absolute;
    right: 20px;
  }
 } 

/* media queries */
  