body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/img/background.jpg");
  height: fit-content;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* //min-height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* usama */
.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
  border-color: #608d8d !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #608d8d !important;
}
/* CARDS */

.home .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home .card {
  margin: 20px;
  padding: 20px;
  width: 200px;
  min-height: 50px;
  max-height: 100px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  position: relative;
}

.home .card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.home .card__link,
.home .card__exit,
.home .card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.home .card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.home .card__link:hover::after {
  width: 100%;
}

.home .card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.home .card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.home .card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #ffffff;
}

.home .card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */
.home .card-1 {
  background: radial-gradient(#507978, #82b6b5);
  cursor: pointer;
}

.home .card-2 {
  background: radial-gradient(#507978, #82b6b5);
  cursor: pointer;
}

/* RESPONSIVE */
@media (max-width: 1600px) {
  .home .cards {
    justify-content: center;
  }
}

.home .cards .card .buttonText {
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
  width: 100%;
  height: auto;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home .customDialogButton {
  padding: 15px 40px;
  padding-top: 17px;
  font-size: 14px;
  border: 1px solid #70a09f;
  color: #70a09f;
}

.home .customDialogButton:hover {
  background-color: #70a09f;
  color: #fff;
  border: 1px solid #70a09f;
}

@media screen and (min-width: 960px) and (max-width: 1160px) {
  .home .card {
    width: 200px;
  }
}

@media screen and (min-width: 200px) and (max-width: 960px) {
  .home .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 10px;
  }
  .home .cards .card {
    margin: 0;
    width: 20%;
    min-height: 18;
    min-width: 200;
  }
  .home .tab-main-flex .topPopupButton {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 765px) and (max-width: 850px) {
  .home .cards .card {
    margin-left: 70;
    margin-right: 70;
    width: 20%;
    min-height: 18;
    min-width: 200;
  }
}

@media screen and (min-width: 200px) and (max-width: 650px) {
  .home .cards {
    grid-template-columns: 1fr;
  }
  .home .cards .card {
    min-height: 50px;
    margin-left: 70;
    margin-right: 70;
  }
  .home .cards .card .buttonText {
    font-size: 20px;
  }
  .home .tab-main-flex {
    height: 360px !important;
  }
  .home .tab-main-flex .topPopupButton {
    margin: 20px 0;
  }
}
