.home{
    width: 100%;
}
.popbtns{
    display: flex;
    width: 100%;
}
.topPopupButton{
    width: 50%;
    text-align: right;
    padding-right: 25px;
}
.downPopupButton{
    width: 50%;
    text-align: left;
    padding-left: 25px;
}
.tab-main-flex{
    margin: 40px; 
    height: 300px;
    flex-direction: column;
}

@media only screen and (max-width: 425px) { 
    .tab-main-flex{
        margin: 40px; 
        height: 300px;
        flex-direction: column;
    }
    .popbtns{
        display: block;
        width: 100%;
        padding: 20px 0px;
    }
    .topPopupButton{
        width: 100%;
        text-align: center;
        padding: 0px 0px 0px 0px;
    }
    
    .downPopupButton{
        width: 100%;
        text-align: left;
    }
    .home .customDialogButton {
        font-size: 10px !important;
    }
    .card{
        height: 100px;
    }
} 
@media only screen and (max-width: 375px) { 
    .downPopupButton Button{
        margin-right: 20px;
        
    }
} 
@media only screen and (min-width: 375px) and (max-width:415px) { 
    .downPopupButton Button{
        margin-right: 20px;
        
    }
} 
@media only screen and (min-width: 426px){ 
    .videoDiv{
        margin: 50px;
   }
};