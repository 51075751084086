.profilePic {
    width: 100%;
    max-width: 150px;
}
.profilePic:hover {
    width : 100%;
    max-width: 400px;
}

.form-wrapper{
    background-color: white;
}
.card-body{
    background-color: white;
}
/* .inner-div{
    height: 100%;
    overflow-y: scroll;
}
.outer-div{
    
    
} */
/* .card-body{
    
    overflow-y: scroll;
} */
@media (max-width: 990px){
    .container-top{
      /* style={{overflowY:"scroll",height:"80vh"}} */
      overflow-y: scroll;
      height:80vh;
    }
    }